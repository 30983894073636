import React from 'react'
import { Link } from 'react-router-dom'
import { CardEvents } from '../Card/CardEvents'
import { EVENTS } from './listEvents'
import "./index.css"
export const Events = () => {
  return (
    <Link to='/exhibitions'>

    <div className='event'>
        {
            EVENTS.map((e,i)=><CardEvents key={i} img={e.img} name={e.name}/>)
        }
    </div>
    </Link>
  )
}
