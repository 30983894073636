import img from "./imgsEvents/event.jpg"
import imgA from "./imgsEvents/event2.jpg"
export const EVENTS = [
    {
        img:imgA,
        name:"asd"
    },{
        img,
        name:""
    },{
        img:imgA,
        name:""
    },{
        img,
        name:""
    },{
        img:imgA,
        name:""
    },{
        img,
        name:""
    },
]