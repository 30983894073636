import img from '../../componets/Events/imgsEvents/event.jpg'
import img2 from '../../componets/Events/imgsEvents/event3.png'
import img3 from '../../componets/Events/imgsEvents/expoAbril.png'
import img4 from '../../componets/Events/imgsEvents/expoAgosto.png'
import img5 from '../../componets/Events/imgsEvents/expoSeptiembre.png'
export const expo = [
    { 
        day: "08",
        month: 'Abril',
        image: img3

    },
    { 
        day: "08/09",
        month: 'Mayo',
        image: img2

    },
    
    { 
        day: "08/09",
        month: 'Agosto',
        image: img4

    },
    { 
        day: "17",
        month: 'Septiembre',
        image: img5

    },
    { 
        day: "08/09",
        month: 'Mayo',
        image: img

    }

]