import React from "react";
import "./index.css";
import { expo } from "./card";
import { CardExpo } from "../../componets/Card/CardExpo";
import img from "../../componets/Events/imgsEvents/event.jpg";
import copa from "../../assets/copa.png";
export default function Exhibitions() {
  return (
    <div className="aboutDivBack">
      <div
        style={{
          height: "100vh",
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          display: "flex",
        }}
      >
        <h1
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: 40,
            color: "#B06440",
          }}
        >
          Exposiciones 2023
        </h1>
      </div>
      <div className="expo">
        <div className="containerr">
          <h3>Calendario 2023</h3>
          <div className="div">
            {expo.map((e) => (
              <CardExpo day={e.day} image={e.image} month={e.month} />
            ))}
          </div>
          <button className="button-inscripciones">Inscripciones</button>
          <div className="cont">
            <iframe className="iframe" title="unique" src={copa}></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
