import React, { useState } from 'react'
import { CardMember } from '../Card/CardMember'
import { members } from './members'

export const Members = () => {
    return (
        <div className='membersdiv'>
              {
                members.map(e=><CardMember description={e.description} img={e.image} name={e.name}/>)
              }
        </div>
  );
}
