export const members = [
    {
        name:"club boyero",
        image:"https://media.licdn.com/dms/image/C4E0BAQFtdLj5pB2KcQ/company-logo_200_200/0/1628019627168?e=2147483647&v=beta&t=Je-YIeVjSm01PsMw9ohxL7NAXGf0232Jqf7vq3uKIx4",
        description:"Criadero 1."
    },{
        name:"club boyero",
        image:"https://media.licdn.com/dms/image/C4E0BAQFtdLj5pB2KcQ/company-logo_200_200/0/1628019627168?e=2147483647&v=beta&t=Je-YIeVjSm01PsMw9ohxL7NAXGf0232Jqf7vq3uKIx4",
        description:"Nacimos en 2001 y en Argentina impulsados por la pasión emprendedora y un objetivo claro: mejorar la vida de las mascotas a través del desarrollo, producción, comercialización y distribución de las mejores soluciones nutricionales integrales."
    },{
        name:"club boyero",
        image:"https://media.licdn.com/dms/image/C4E0BAQFtdLj5pB2KcQ/company-logo_200_200/0/1628019627168?e=2147483647&v=beta&t=Je-YIeVjSm01PsMw9ohxL7NAXGf0232Jqf7vq3uKIx4",
        description:"Nacimos en 2001 y en Argentina impulsados por la pasión emprendedora y un objetivo claro: mejorar la vida de las mascotas a través del desarrollo, producción, comercialización y distribución de las mejores soluciones nutricionales integrales."
    },{
        name:"club boyero",
        image:"https://media.licdn.com/dms/image/C4E0BAQFtdLj5pB2KcQ/company-logo_200_200/0/1628019627168?e=2147483647&v=beta&t=Je-YIeVjSm01PsMw9ohxL7NAXGf0232Jqf7vq3uKIx4",
        description:"Nacimos en 2001 y en Argentina impulsados"
    }
]