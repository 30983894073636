import './App.css';
import { Home } from './pages/Home/Home';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import { NavBar } from './componets/NavBar/NavBar';
import { Footer } from './componets/Footer/Foter';
// import { Redes } from './componets/Redes/Redes';
import { AboutUs } from './pages/AboutUs/AboutUs';
import { Gallery } from './pages/Gallery/Gallery';
import Exhibitions from './pages/Exhibitions/Exhibitions.jsx'
import Contact from './pages/ContactUs/contact';
import { UserContext } from './componets/globalContext/userContext';
import News from './pages/News/News';
function App() {
  return (
    <UserContext>
    <div className='app'>
      <BrowserRouter className="App">
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home/>}/>        
          <Route path="/aboutUs" element={<AboutUs/>}/>        
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/exhibitions" element={<Exhibitions/>}/>
          <Route path="/news" element={<News/>}/>

        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
    </UserContext>
  );
}

export default App;
