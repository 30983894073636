import React from 'react'
import '../../pages/Exhibitions/index.css'
export const CardExpo = ({day,month,image}) => {
  return (
    <div className='exp' style={{width:300,height:400,margin:"0 10px"}}>   
        <img style={{objectFit:"contain",width:300,height:400}} src={image} alt=''/>
        <h5>Dia: {day}</h5>
        <h5>Mes: {month}</h5>
        <h5>Lugar: </h5>
    </div>
  )
}