import React,{ useEffect, useState} from "react";

const Context = React.createContext({})

export const UserContext= ({children})=>{
    const [isPhone,setIsPhone] = useState(true)
  useEffect(()=>{
    const userAgent = navigator.userAgent;
    setIsPhone(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent));
  })
    return <Context.Provider value={{isPhone}}>
    {children}
    </Context.Provider>
}

export default Context