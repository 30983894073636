import React from 'react'
import { CardNew } from '../../componets/Card/CardNews'
import { news } from './new.js'
import "./index.css"
import img from '../../assets/boyero.jpg'
export default function News() {
  return (
    <div className="aboutDivBack">
      <div
        style={{
          height: "100vh",
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          display: "flex",
        }}
      >
        <h1
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-around",
            fontSize: 40,
            color: "white",
            padding: "20px",
          }}
        >Noticias
        </h1>
      </div>
    <div className='cont'>
            {news.map((e) => (
        <div className='news'>
              <CardNew 
              img={e.img} descripcion={e.descripcion} />
        </div>
            ))}
    </div>
    </div>
  )
}